import React from "react"
import { Formik } from "formik"
import { useIdentityContext } from "react-netlify-identity"
import { navigate } from "gatsby"
import { object, string } from "yup"
import { errorMessages } from "@const"
import SetNewPassword from "./SetNewPassword"

export default () => {
  const { setUser, updateUser } = useIdentityContext()
  const formikProps = {
    initialValues: {
      password: "",
    },
    onSubmit: ({ password }) =>
      updateUser({ password })
        .then(() => {
          setUser(undefined)
          navigate("/login")
        })
        .catch(error => {
          console.log(error)
        }),
    validationSchema: object().shape({
      password: string().required(errorMessages.required),
    }),
  }
  return (
    <Formik
      {...formikProps}
      children={props => <SetNewPassword {...props} />}
    />
  )
}
