import React, { useState } from "react"
import { Alert, IconButton, Input, Label } from "theme-ui"
import { ErrorMessage, useField } from "formik";
import PasswordHidden from "@assets/password-hidden.svg";
import PasswordVisible from "@assets/password-visible.svg";

export default ({children, placeholder, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const [isPasswordVisible, setPasswordVisibility] = useState(false)
  const { name } = field;
  const { error } = meta;

  const togglePasswordVisibility = e => {
    setPasswordVisibility(!isPasswordVisible)
    e.preventDefault()
  }
  return (
    <Label>
      <Input
        type={isPasswordVisible ? "text" : "password"}
        placeholder={placeholder}
        sx={{
          borderColor: error && `danger`
        }}
        {...field}
        {...helpers}
        {...props}
      />
      <IconButton
        onClick={togglePasswordVisibility}
        variant="buttons.icon.togglePasswordVisibility"
      >
        {isPasswordVisible ? <PasswordVisible /> : <PasswordHidden />}
      </IconButton>
      <ErrorMessage
        name={name}
        component={({ children }) => (
          <Alert variant="fieldError">{children}</Alert>
        )}
      />
      {children}
    </Label>
  )
}
