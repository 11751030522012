import React from "react"
import PropTypes from "prop-types"
import { Container, Flex } from "theme-ui"
import { Global } from "@emotion/core"
import Logo from "@common/Logo"
import SEO from "@components/seo"
import AvatarButton from "@components/AvatarButton"
import MainNavigation from "@components/MainNavigation"
import globalStyles from "@styles/global"
import { colorModes } from "@const"
import "normalize.css"

const { light } = colorModes
const colorMode = light

const Layout = ({ children, title }) => {
  return (
    <>
      <SEO title={title} />
      <Global styles={globalStyles} />
      <Container as="header" variant="layout.header">
        <Logo colorMode={colorMode} />
        <AvatarButton colorMode={colorMode} />
      </Container>
      <MainNavigation colorMode={colorMode} />
      <Flex as="main" variant="layout.main">
        <Container
          as="section"
          variant="layout.form.container"
        >
          {children}
        </Container>
      </Flex>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Layout
