import React from "react"
import { Form } from "formik"
import { Button, Flex } from "theme-ui"
import PasswordInput from "@fields/PasswordInput"

export default ({ dirty, isSubmitting, isValid, values, ...props }) => (
  <Flex as={Form} sx={{ flexWrap: `wrap` }}>
    <PasswordInput name="password" placeholder="Hasło" />
    <Button
      disabled={!dirty || !isValid || isSubmitting}
      type="submit"
      variant="submit"
    >
      Zapisz nowe hasło
    </Button>
  </Flex>
)
